import ResetForm, {useResetForm} from 'hooks/event';
import {withGeneratedId} from 'lib/generator';
import {useYupResolver} from 'lib/react-hook-form/use-yup-resolver';
import {timeOut} from 'lib/utils';
import {isFunction} from 'lodash';
import {useCallback, useMemo, useRef} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {LoggerService} from 'services/logger.service';

export function useYupValidationResolver(validationSchema, props = {abortEarly: false}) {
  const describe = useMemo(
    () => (isFunction(validationSchema?.describe) ? validationSchema?.describe() : null),
    [validationSchema],
  );

  const {resolver} = useYupResolver({
    validationSchema,
    onError(errors) {
      LoggerService.error('useYupValidationResolver', errors);
    },
    ...props,
  });

  return {describe, resolver};
}

/**
 *
 * @param props {UseFormProps}
 * @returns {JSX.Element}
 * @constructor
 */
function Form(props) {
  const formEl = useRef(null);

  const {
    children,
    onSubmit,
    onError,
    defaultValues,
    validationSchema,
    validationSchemaOptions,

    onKeyUp,
    id,
    withReset = true,
    defaultValuesOmit,

    mode,
    reValidateMode,
    shouldUnregister = false,
    shouldFocusError,
    criteriaMode,
    delayError,
    shouldUseNativeValidation,

    onChange,

    ...rest
  } = props;

  useResetForm({enabled: withReset, defaultValues, id, defaultValuesOmit});

  const {describe, resolver} = useYupValidationResolver(validationSchema, validationSchemaOptions);

  const methods = useForm({
    mode,
    reValidateMode,
    defaultValues,
    resolver,
    shouldUnregister,
    shouldFocusError,
    context: {id},
    criteriaMode,
    delayError,
    shouldUseNativeValidation,
  });

  const resetForm = useCallback(
    /**
     *
     * @param data
     * @param options {KeepStateOptions}
     */
    (data = {...defaultValues}, options = {keepIsSubmitted: true, keepDirty: false, keepSubmitCount: true}) => {
      methods.reset(data, options);
    },
    [methods, defaultValues],
  );

  const submitFunction = useMemo(
    () =>
      methods.handleSubmit((data, event) => {
        /**
         * Regarding this issue from react-hook-form, the library make an issue when it works with multiple forms, so if the form event id is not the same as id, it should prevent the submit
         * https://github.com/react-hook-form/react-hook-form/issues/2076
         */
        if (id !== event?.target?.id) return undefined;

        return onSubmit(data, event);
      }, onError),
    [methods, onError, id, onSubmit],
  );

  const triggerSubmit = useCallback(async () => {
    return new Promise(async (resolve) => {
      await timeOut(200);

      const formButton = document.querySelector(`form[id="${id}"] button[type="submit"]`);
      const submitButton = document.querySelector('[type="submit"]');

      if (formButton) formButton.click();
      else if (submitButton) submitButton.click();

      return resolve();
    });
  }, [id]);

  const formContextProps = useMemo(
    () => ({
      triggerSubmit,
      resetForm,
      describe,
      defaultValues,
      validationSchema,
    }),
    [describe, resetForm, triggerSubmit, defaultValues, validationSchema],
  );

  return (
    <FormProvider {...methods} {...formContextProps}>
      <form id={id} onSubmit={submitFunction} {...rest} ref={formEl}>
        <ResetForm id={id} withReset={withReset} />
        {children}
      </form>
    </FormProvider>
  );
}

export default withGeneratedId(Form);
