import {Box} from '@material-ui/core';
import {isPending} from 'adapter/loading.adapter';
import clsx from 'clsx';
import Form from 'components/common/form/Form';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Text from 'components/common/typeography/text/Text';
import EmailIllustrationsFields from 'components/illustrator/modal-email-to-borrower/components/email-illustrations/components/email-illustrations-fields/EmailIllustrationsFields';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/email-illustrations/EmailllustrationsStyles';
import {useDialogStateMutation, withDialogState} from 'hooks/dialog';
import {Trans, useTranslation} from 'lib/i18n';
import {ToString} from 'lib/utils';
import yup from 'lib/validation';
import {useShareIllustrationsMutation} from 'query/illustrator/save-email-illustrations';
import {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {getSelectedIllustration} from 'reducers/illustrator.reducer';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from '../../../../../constants/analytic-events';
import {trackEvent} from '../../../../../services/analytics.service';

/**
 *
 * "Email Illustrations" component
 *
 *    parent component : modal "Email to Borrower"
 *
 */
const validationSchema = yup.object().shape({
  eiFormList: yup.array().of(
    yup.object().shape({
      emailAddress: yup.string().email().required(),
    }),
  ),
});

function EmailIllustrations(props = {}) {
  // props
  const {state, setState, nextStep} = props;

  useEffect(() => {
    setState((preState) => ({...preState, closeDialogAndSendAnalyticsEvent: false}));
  }, [setState]);

  // load the selected illustration data
  const selectedIllustration = useSelector(getSelectedIllustration);

  const {t} = useTranslation();
  const classes = useStyles();

  const shareIllustrationsMutation = useShareIllustrationsMutation();

  const doSubmit = useCallback(
    async (data) => {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_END);

      if (data?.eiFormList?.length) {
        await shareIllustrationsMutation.mutateAsync({
          toEmails: data.eiFormList.map((obj) => obj.emailAddress),
          pdfs: state.pdfs,
        });

        return nextStep();
      } else {
        // TODO : throw error
      }
    },
    [shareIllustrationsMutation, state.pdfs, nextStep],
  );
  // eiFormList : email illustration form list
  const defaultValues = useMemo(() => {
    return {
      eiFormList: [
        {
          emailAddress: ToString(selectedIllustration?.BorrowerProfile?.Email),
        },
      ],
    };
  }, [selectedIllustration]);

  return (
    <Form id={state.formId} onSubmit={doSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
      <EmailIllustrationsButtonManager setState={setState} loading={shareIllustrationsMutation.loading} />
      <Box>
        <Text className={clsx(classes.textAlignCenter, classes.subTitle)}>
          <Trans i18nKey="emailIllustrationsTranslation:email info" />
        </Text>
      </Box>

      <Container className={classes.itemContainer}>
        <Item xs={12}>
          <Text>{t('emailIllustrationsTranslation:borrower email address')}</Text>

          <EmailIllustrationsFields />
        </Item>
      </Container>
    </Form>
  );
}

function EmailIllustrationsButtonManager(props) {
  const {setState, loading} = props;

  const {watch} = useFormContext();
  const formValues = watch();

  const buttonNextDisabled = useMemo(() => {
    try {
      validationSchema.validateSync(formValues);

      return false;
    } catch (e) {
      return true;
    }
  }, [formValues]);

  const buttonNextProgress = useMemo(() => isPending({loading}), [loading]);

  useDialogStateMutation({setState, buttonNextDisabled: buttonNextDisabled || buttonNextProgress, buttonNextProgress});

  return null;
}

export default withDialogState(EmailIllustrations, {
  title: 'emailIllustrationsTranslation:title',
  formId: 'email-illustrations',
  buttonNext: 'button.send',
  buttonReturn: '',
});
