import {useTheme} from '@material-ui/core';
import equityAvailImg from 'assets/images/products/equityavail.svg';
import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import TaxesAndInsuranceReserveInput from 'components/common/inputs/TaxesAndInsuranceReserveInput';
import {API_ROUTES} from 'constants/illustrator';
import {useIllustrationContext} from 'contexts/illustration-context';
import {useDeepMemo} from 'hooks/utils';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation} from 'lib/validation/validation';
import {isFunction} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {DEFAULT_HYBRID_METRIC, DEFAULT_HYBRID_RESULT} from 'reducers/illustrator.reducer';

const additionalStepValidation = yup.object({
  OverrideClosingCosts: yup.bool(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.HYBRID,
    annualPayment: yup.number().max(0),
    minMonthlyPayment: yup.number(),
    monthlyPayment: yup.number().test({
      name: 'minValue',
      exclusive: true,
      test: function (item) {
        const {path, createError} = this;

        // monthlyPayment is negative on hybrid product
        if (item > this.parent.minMonthlyPayment) {
          return createError({
            path,
            params: {minValue: this.parent.minMonthlyPayment},
          });
        }

        return true;
      },
    }),
  }),
});

function HybridCashAtClose(props) {
  const {onChange: doChange, ...rest} = props;

  const {advancedOptions} = useIllustrationContext();

  const {setValue} = useFormContext();
  const onChange = useCallback(
    (e) => {
      setValue(
        'advancedOptions',
        advancedOptions?.map((option) => ({
          ...option,
          minMonthlyPayment: 0,
          monthlyPayment: 0,
        })),
        {shouldTouch: false, shouldDirty: false, shouldValidate: false},
      );
      if (isFunction(doChange)) doChange(e);
    },
    [doChange, setValue, advancedOptions],
  );
  return <Input name="CashAtClose" type="currency" onCustomChange={onChange} {...rest} />;
}

export function useHybridHook(props = {}) {
  const {product: {calculation: {OverrideClosingCosts, OverrideClosingCostsKey} = {}} = {}} = props;

  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
  }, [OverrideClosingCosts, OverrideClosingCostsKey]);

  const toggleDisabled = useCallback((event) => {
    setIsDisabled(!event);
  }, []);
  const theme = useTheme();

  return {
    description: t('illustratorTranslation:illustration.products.hybrid.description'),

    img: equityAvailImg,

    skipAdditionalStep: true,
    additionalQuickFill: useMemo(
      () => [
        <Switch order="4" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
        <TaxesAndInsuranceReserveInput order="2" defaultValue={0} />,
        <HybridCashAtClose order="1" defaultValue={0} />,
      ],
      [isDisabled, toggleDisabled],
    ),
    additionalStep: useMemo(
      () => [<Input order="3" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />],
      [isDisabled],
    ),
    additionalStepValidation,
    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'homeValueYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
        {
          key: 'netEquityValue',
          group: 3,
          formatter: (props) => formatCurrency(props.row.netEquityValue),
          minWidth: 100,
          listData: 'netEquityValue',
        },
      ],
      [],
    ),
    additionalAdvancedOptionHeader: useMemo(
      () => [
        {
          key: 'rateAdjustments',
          group: 1,
          editable: true,
          formatter: ({row}) => formatPercent(row.rateAdjustments),
          minWidth: 100,
        },
        {
          key: 'locYearEnd',
          group: 2,
          formatter: ({row}) => formatCurrency(row.locYearEnd),
          listData: 'locYearStart',
        },
      ],
      [],
    ),
    illustratorBorrowerConfig: {
      declaration: [
        {
          value: 'OutstandingJudgement',
          order: 1,
        },
        {
          value: 'DeclaredBankruptcy',
          order: 2,
        },
        {
          value: 'DeclaredForeclosure',
          order: 3,
        },
        {
          value: 'LawsuitParty',
          order: 4,
        },
        {
          value: 'LoanObligation',
          order: 5,
        },
        {
          value: 'LoanDelinquency',
          order: 6,
        },
        {
          value: 'AlimonyObligation',
          order: 7,
        },
        {
          value: 'DownPaymentBorrowed',
          order: 8,
        },
        {
          value: 'NoteEndorser',
          order: 9,
        },
        {
          value: 'UsCitizen',
          order: 10,
        },
        {
          value: 'ResidentAlien',
          order: 11,
        },
        {
          value: 'NonResidentAlien',
          order: 12,
          options: [
            {
              name: 'ImmigrationStatus',
              type: 'dropDown',
              dropDownSource: 'immigrationStatus',
              order: 1,
            },
          ],
        },
        {
          value: 'PropertyOccupation',
          order: 13,
        },
        {
          value: 'PropertyOwnershipInterest',
          order: 14,
          options: [
            {
              name: 'PropertyOwnershipInterestPropertyType',
              type: 'dropDown',
              dropDownSource: 'propertyOwnershipInterestPropertyType',
              order: 1,
            },
            {
              name: 'PropertyOwnershipInterestTitleHold',
              type: 'dropDown',
              dropDownSource: 'propertyOwnershipInterestTitleHold',
              order: 2,
            },
          ],
        },
      ],
    },

    additionalComparisonViewData: useDeepMemo(() => {
      const {
        calculation: {
          HybridMetric: {PaymentTermYears} = DEFAULT_HYBRID_METRIC,
          HybridResult: {NewMonthlyPayment} = DEFAULT_HYBRID_RESULT,
          BorrowerProfile: {ExistingMortgage: {TaxesAndInsuranceReserve, TotalTaxAndInsurancePayment} = {}} = {},
        } = {},
      } = props.product ?? {};

      return [
        {
          primary: t('illustratorTranslation:product results.product result pie.t&i payment'),
          secondary: formatCurrency(TaxesAndInsuranceReserve),
          key: 'TaxesAndInsuranceReserve',
          color: theme.palette.secondary.main,
        },
        {
          primary: t('illustratorTranslation:product results.product result pie.taxes & insurance'),
          secondary: formatCurrency(TotalTaxAndInsurancePayment),
          key: 'TotalTaxAndInsurancePayment',
          color: theme.palette.neutral.light,
        },
        {
          primary: t('illustratorTranslation:product results.product result pie.payment'),
          secondary: formatCurrency(NewMonthlyPayment),
          key: 'NewMonthlyPayment',
          color: theme.palette.primary.main,
        },
        {
          primary: t('illustratorTranslation:product results.product result pie.payment term'),
          secondary: PaymentTermYears,
          key: 'PaymentTermYears',
          color: theme.palette.secondary.main,
        },
      ];
    }, [props.product]),
  };
}
