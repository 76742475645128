import {IGNORE_AUTO_LOGIN, ROUTES} from 'constants/routes';
import {generateId} from 'lib/generator';
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {usePromise} from 'react-use';
import {isAnonymousAuth, oktaAuth} from 'services/auth.service';

function AutoLogin(props) {
  const {children, ignoredPaths = IGNORE_AUTO_LOGIN} = props;

  const [state, setState] = useState({key: generateId(), renderChildren: false});

  const history = useHistory();
  const location = useLocation();
  const mounted = usePromise();

  const brokerPortalRedirectEnable = window.env.REACT_APP_BROKER_PORTAL_REDIRECT_ENABLE === 'true';

  if (brokerPortalRedirectEnable && window.top === window.self) {
    const pathname = window.location.pathname;

    if (!/widgets/gi.test(pathname)) {
      window.location.href = `${window.env.REACT_APP_BROKER_PORTAL_URL}/illustrator/scenario`;
    }
  }

  useEffect(() => {
    (async function () {
      const renderChildren = await mounted(
        new Promise(async (resolve) => {
          const skipAutoLogin = ignoredPaths.find((path) => new RegExp(path, 'gi').test(window.location));

          if (skipAutoLogin) {
            return resolve(true);
          }

          if (oktaAuth.isLoginRedirect()) {
            try {
              await oktaAuth.handleLoginRedirect();
            } catch (ex) {
              if (!isAnonymousAuth(ex)) {
                history.push(ROUTES.AUTH_ERROR_PAGE, {error: ex});
              }
            }

            return resolve(true);
          } else if (!(await oktaAuth.isAuthenticated())) {
            oktaAuth.setOriginalUri(location.pathname);
            await oktaAuth.signInWithRedirect({
              prompt: 'none',
            });
          } else {
            oktaAuth.authStateManager.updateAuthState();
            return resolve(true);
          }

          return resolve(false);
        }),
      );
      // This line will not execute if <AutoLogin> component gets unmounted.
      setState({key: generateId(), renderChildren});
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment key={state.key}>{state.renderChildren ? children : null}</React.Fragment>;
}

export default AutoLogin;
