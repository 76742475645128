import FeatureWrapperStepWizard from 'components/common/step-wizard/FeatureWrapperStepWizard';
import IllustratorHeader from 'components/illustrator/illustrator-header/IllustratorHeader';
import {generateId} from 'lib/generator';
import {useTranslation} from 'lib/i18n';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';
import {useIllustratorBorrowerWizard} from './IllustratorBorrowerWizardConfig';
import IllustratorBorrowerWizardProductSelection from './IllustratorBorrowerWizardProductSelection';
import useStyles from './IllustratorBorrowerWizardStyles';

IllustratorBorrowerWizard.propTypes = {};

function IllustratorBorrowerWizard(props) {
  const illustratorBorrower = useSelector(getSelectedIllustratorBorrower);
  const {t} = useTranslation();

  const classes = useStyles();

  const {steps} = useIllustratorBorrowerWizard({ApiRoute: illustratorBorrower.ApiRoute});

  const key = useMemo(() => generateId(), []);

  return (
    <IllustratorBorrowerWizardProductSelection
      key={key}
      headerComp={
        <IllustratorHeader
          illustration={illustratorBorrower}
          backButton={t('illustratorTranslation:buttons.exit button')}
        />
      }
    >
      <FeatureWrapperStepWizard
        id={illustratorBorrower.id}
        className={classes.stepWizardContainer}
        initialStep={1}
        nav={
          <IllustratorHeader
            illustration={illustratorBorrower}
            backButton={t('illustratorTranslation:buttons.back button')}
            exitButton={t('illustratorTranslation:buttons.exit button')}
          />
        }
      >
        {steps.map(({component: Component = React.Fragment, hashKey, ...props}) => {
          return <Component key={hashKey} hashKey={hashKey} {...props} />;
        })}
      </FeatureWrapperStepWizard>
    </IllustratorBorrowerWizardProductSelection>
  );
}

export default IllustratorBorrowerWizard;
