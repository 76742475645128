import i18n from 'i18next';
import React, {useCallback, useMemo} from 'react';
import {Trans as I18nTrans, useTranslation as i18nTranslation, initReactI18next} from 'react-i18next';

import {isDevelop} from 'constants/app';
import {LANGUAGES} from 'constants/i18n';
import {useAppBreakpoints} from 'lib/app-theme';
import resources from 'locales';

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: LANGUAGES.EN,
  lng: LANGUAGES.EN,
  debug: isDevelop,
  ns: Object.keys(resources.en),
  nsSeparator: ':',
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
});

export function addI18nResource(lang, ns, resource) {
  return i18n.addResourceBundle(lang, ns, resource, true, true);
}

/**
 *
 * @param props {TransProps<KeyPrefix<Namespace>, Namespace, UseTranslationOptions<KeyPrefix<Namespace>>>}
 * @returns {React.ReactElement}
 * @constructor
 */
export function Trans(props) {
  return (
    <I18nTrans
      {...props}
      components={{
        ...props?.components,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        a: <a children=" " />,
        span: <span />,
        sup: <sup />,
      }}
    />
  );
}

/**
 *
 * @param ns {Namespace = undefined}
 * @param options {UseTranslationOptions<KeyPrefix<ns>> = undefined}
 * @returns {UseTranslationResponse<ns, options> & {override}}
 */
export function useTranslation(ns, options) {
  const {t, i18n, ...r} = i18nTranslation(ns, options);

  const translate = useCallback(
    /**
     *
     * @type {TFunction<ns, options>}
     */
    (keys, params) => {
      if (i18n.exists(keys, params)) return t(keys, params);
      return '';
    },
    [t, i18n],
  );

  const override = useCallback(
    (keys, params = {}) => {
      const translationKeys = keys.split('.');

      const keyString = translationKeys.pop();
      const keysString = translationKeys.join('.');

      const {product: {ApiRoute} = {}, ...rest} = params;

      const key = `${keysString}.override.${ApiRoute}.${keyString}`;

      if (i18n.exists(key, rest)) return t(key, rest);
      return translate(keys, rest);
    },
    [t, i18n, translate],
  );

  const cashLabel = useCallback(
    (keys, params = {}) => {
      const {cash, ...rest} = params;
      let keyType = 'positive';
      if (cash < 0) {
        keyType = 'negative';
      }
      const key = `${keys}.${keyType}`;

      if (i18n.exists(key, rest)) return t(key, rest);
      return translate(keys, rest);
    },
    [t, i18n, translate],
  );

  return {t: translate, override, cashLabel: cashLabel, i18n, ...r};
}

export function useResponsiveText(key = '') {
  const {i18n} = i18nTranslation();
  const {isMobileApp, isSmSizeApp, isTabletApp, isLgSizeApp, isXlSizeApp} = useAppBreakpoints();

  const result = useMemo(() => {
    let size = '';

    if (isXlSizeApp && i18n.exists(`${key}-xl`)) size = 'xl';
    else if (isLgSizeApp && i18n.exists(`${key}-lg`)) size = 'lg';
    else if (isTabletApp && i18n.exists(`${key}-md`)) size = 'md';
    else if (isSmSizeApp && i18n.exists(`${key}-sm`)) size = 'sm';
    else if (isMobileApp && i18n.exists(`${key}-xs`)) size = 'xs';

    if (size) return `${key}-${size}`;

    return key;
  }, [i18n, key, isMobileApp, isSmSizeApp, isTabletApp, isLgSizeApp, isXlSizeApp]);

  return result;
}
