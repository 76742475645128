import selectImg from 'assets/images/products/select.svg';
import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import UpfrontDrawPercentage from 'components/common/inputs/UpfrontDrawPercentage';
import Text from 'components/common/typeography/text/Text';
import SelectRatesSelect from 'components/products/select/SelectRatesSelect';
import {API_ROUTES} from 'constants/illustrator';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {ToNumber} from 'lib/utils';
import yup from 'lib/validation';
import {currencyField, getAdvancedOptionsFieldDefaultValidation} from 'lib/validation/validation';
import {useCallback, useEffect, useMemo, useState} from 'react';

export const MIN_UPFRONT_DRAW_PERCENTAGE = 25;
export const MAX_UPFRONT_DRAW_PERCENTAGE = 100;

const additionalStepValidation = yup.object({
  TotalUpfrontDraw: currencyField({required: false}).when('ApiRoute', {
    is: API_ROUTES.SELECT,
    otherwise: (schema) => schema.notRequired(),
  }),
  upfrontDrawPercentage: yup
    .number()
    .transform((currentValue) =>
      ToNumber(currentValue) < MIN_UPFRONT_DRAW_PERCENTAGE ? MIN_UPFRONT_DRAW_PERCENTAGE : currentValue,
    )
    .when('ApiRoute', {
      is: API_ROUTES.SELECT,
      then: (schema) => schema.min(MIN_UPFRONT_DRAW_PERCENTAGE).max(MAX_UPFRONT_DRAW_PERCENTAGE).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  LineOfCredit: currencyField({required: false}),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  Margin: yup.number().nullable(),
  CashAtClose: yup.number().nullable(),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.SELECT,
    annualPayment: yup.number(),
    monthlyPayment: yup.number(),
  }),
});

export function useSelectHook(props) {
  const {product: {calculation: {OverrideClosingCosts, OverrideClosingCostsKey, ClosingCost} = {}} = {}} = props;

  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [maxClosingCost, setMaxClosingCost] = useState(ClosingCost);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
    if (!OverrideClosingCosts) {
      setMaxClosingCost(ClosingCost);
    }
  }, [ClosingCost, OverrideClosingCosts, OverrideClosingCostsKey]);

  const toggleDisabled = useCallback((event) => {
    setIsDisabled(!event);
  }, []);
  const fee = props.product?.calculation?.Fee ? props.product.calculation.Fee : 0;
  return {
    description: t('illustratorTranslation:illustration.products.select.description'),

    img: selectImg,

    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'locYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.locYearEnd),
          minWidth: 100,
          listData: 'locYearStart',
        },
        {
          key: 'homeValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 3,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [],
    ),
    additionalAdvancedOptionHeader: useMemo(
      () => [
        {
          key: 'rateAdjustments',
          group: 1,
          editable: true,
          formatter: ({row}) => formatPercent(row.rateAdjustments),
          minWidth: 100,
        },
        {
          key: 'locYearEnd',
          group: 2,
          formatter: ({row}) => formatCurrency(row.locYearEnd),
          listData: 'locYearStart',
        },
      ],
      [],
    ),
    advancedOptionsDisclaimer: t(
      'illustratorTranslation:illustration.products.select.advanced options disclaimer',
    ).replace('{{fee}}', formatCurrency(fee)),
    additionalStepValidation,
    additionalStepTitle: t('illustratorTranslation:illustration.products.select.additional step title'),
    additionalQuickFill: useMemo(
      () => [
        <Input order="2" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <div order="3">
          {!isDisabled && (
            <Text order="3" variant="caption" colorVariant="grey">
              Max closing costs {formatCurrency(maxClosingCost)}
            </Text>
          )}
        </div>,
        <Switch order="4" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
      ],
      [isDisabled, maxClosingCost, toggleDisabled],
    ),
    additionalStep: useMemo(
      () => [
        <SelectRatesSelect order="1" name="Margin" />,
        <Input order="5" name="TotalUpfrontDraw" type="currency" defaultValue={0} />,
        <UpfrontDrawPercentage
          order="6"
          min={MIN_UPFRONT_DRAW_PERCENTAGE}
          max={MAX_UPFRONT_DRAW_PERCENTAGE}
          defaultValue={MIN_UPFRONT_DRAW_PERCENTAGE}
        />,
        <Input order="7" name="CashAtClose" type="currency" disabled defaultValue={0} />,
        <Input
          order="8"
          name="LineOfCredit"
          type="currency"
          disabled
          defaultValue={0}
          toolTipText={t('inputsTranslation:LineOfCredit.tooltips.select')}
        />,
      ],
      [t],
    ),
  };
}
