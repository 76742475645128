import {Box, useTheme} from '@material-ui/core';
import {isNotLoaded} from 'adapter/loading.adapter';
import clsx from 'clsx';
import LinearLoader from 'components/common/loader/LinearLoader';
import CurrencyChartAxisTick from 'components/common/recharts/CurrencyChartAxisTick';
import {withLineProps} from 'components/common/recharts/Line';
import ResponsiveContainer from 'components/common/recharts/ResponsiveContainer';
import YearChartAxisTick from 'components/common/recharts/YearChartAxisTick';
import useStyles from 'components/illustrator/illustrator-chart/IllustratorChartStyles';
import {usePrintContext} from 'contexts/print-context';
import {useProductContext} from 'contexts/product-context';
import {useChartAspect, usePrintingValue} from 'hooks/utils';
import {useAppBreakpoints} from 'lib/app-theme';
import {formatCurrency} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';

function IllustratorLineChart(props) {
  const {chartLabel, advancedOptions, isAnimationActive, margin, lifetimeModelChartLines, ...rest} = props;

  const {t} = useTranslation();
  const theme = useTheme();

  const {printing} = usePrintContext();

  const {isTabletApp, isMobileApp} = useAppBreakpoints();

  const data = useMemo(
    () =>
      advancedOptions.map((option) => ({
        ...option,
        name: t('illustratorTranslation:illustration.illustration chart.chart label.name', {
          year: option.year,
          age: option.age,
        }),
      })),
    [advancedOptions, t],
  );

  const optionShowCondition = useCallback((key) => {
    const option = lifetimeModelChartLines.find((line) => line.key === key);
    return typeof option?.condition === 'boolean' ? option.condition : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHomeValueYearEnd = useMemo(
    () => optionShowCondition('homeValueYearEnd') && !!data?.find((d) => d.homeValueYearEnd > 0),
    [data, optionShowCondition],
  );
  const showLoanBalanceValue = useMemo(
    () => optionShowCondition('loanBalanceValueYearEnd') && !!data?.find((d) => d.loanBalanceValueYearEnd > 0),
    [data, optionShowCondition],
  );
  const showNetEquityValue = useMemo(
    () => optionShowCondition('netEquityValue') && !!data?.find((d) => d.netEquityValue > 0),
    [data, optionShowCondition],
  );
  const showExistingMortgageEndBalance = useMemo(
    () => optionShowCondition('existingMortgageEndBalance') && !!data?.find((d) => d.existingMortgageEndBalance > 0),
    [data, optionShowCondition],
  );
  const showLocYearEnd = useMemo(
    () => optionShowCondition('locYearEnd') && !!data?.find((d) => d.locYearEnd > 0),
    [data, optionShowCondition],
  );

  const interval = useMemo(() => {
    if (props.interval) return props.interval;
    if (printing) return 0;
    else if (isTabletApp) return undefined;

    return 0;
  }, [props.interval, printing, isTabletApp]);

  const hideYAxis = useMemo(() => {
    if (printing) return false;
    else if (isMobileApp) return true;

    return false;
  }, [isMobileApp, printing]);

  return (
    <LineChart data={data} margin={{left: !hideYAxis ? theme.spacing(5) : 0, ...margin}} {...rest}>
      <CartesianGrid />

      <XAxis dataKey="name" tick={<YearChartAxisTick rotate={printing || !interval} />} interval={interval} />

      <YAxis hide={hideYAxis} tick={<CurrencyChartAxisTick formatShort={isTabletApp} />} tickMargin={10}>
        {chartLabel}
      </YAxis>

      <Tooltip formatter={(x) => formatCurrency(x)} />

      <Legend />

      <Line
        {...withLineProps({
          hide: !showHomeValueYearEnd,
          name: t('illustratorTranslation:illustration.illustration chart.chart label.homeValueYearEnd'),
          dataKey: 'homeValueYearEnd',
          stroke: theme.palette.primary.dark,
          isAnimationActive,
        })}
      />

      <Line
        {...withLineProps({
          hide: !showLoanBalanceValue,
          name: t('illustratorTranslation:illustration.illustration chart.chart label.loanBalanceValueYearEnd'),
          dataKey: 'loanBalanceValueYearEnd',
          stroke: theme.palette.recommended.main,
          isAnimationActive,
        })}
      />

      <Line
        {...withLineProps({
          hide: !showNetEquityValue,
          name: t('illustratorTranslation:illustration.illustration chart.chart label.netEquityValue'),
          dataKey: 'netEquityValue',
          stroke: theme.palette.primary.main,
          isAnimationActive,
        })}
      />

      <Line
        {...withLineProps({
          hide: !showExistingMortgageEndBalance,
          name: t('illustratorTranslation:illustration.illustration chart.chart label.existingMortgageEndBalance'),
          dataKey: 'existingMortgageEndBalance',
          stroke: theme.palette.recommended.main,
          isAnimationActive,
        })}
      />

      <Line
        {...withLineProps({
          hide: !showLocYearEnd,
          name: t('illustratorTranslation:illustration.illustration chart.chart label.locYearEnd'),
          dataKey: 'locYearEnd',
          stroke: theme.palette.secondary.main,
          isAnimationActive,
        })}
      />
    </LineChart>
  );
}

function IllustratorChart(props) {
  const {chartLabel, childrenContainerClassName, ...rest} = props;

  const {
    product: {calculation: {CalculationId, advancedOptions = []} = {}} = {},
    productDetails: {lifetimeModelChartLines = []} = {},
    loadingProduct: loading,
  } = useProductContext();

  const classes = useStyles();
  const {t} = useTranslation();

  const {chartAspect} = useChartAspect(2);
  const minHeight = usePrintingValue(400, 600);
  const margin = usePrintingValue(undefined, {top: 30, right: 30, left: 180, bottom: 30});

  const productNotLoaded = useMemo(() => isNotLoaded({loading}), [loading]);

  if (productNotLoaded) {
    return <Box {...rest}>{t('illustratorTranslation:illustration.illustration chart.enter info')}</Box>;
  }

  return (
    <LinearLoader
      childrenContainerClassName={childrenContainerClassName}
      showLoader={props.isAnimationActive}
      loading={loading}
    >
      <ResponsiveContainer
        {...rest}
        className={clsx(classes.container, props.className)}
        aspect={chartAspect}
        minHeight={minHeight}
      >
        <IllustratorLineChart
          key={CalculationId}
          chartLabel={chartLabel}
          advancedOptions={advancedOptions}
          lifetimeModelChartLines={lifetimeModelChartLines}
          isAnimationActive={props.isAnimationActive}
          margin={margin}
        />
      </ResponsiveContainer>
    </LinearLoader>
  );
}

IllustratorChart.propTypes = {
  isAnimationActive: PropTypes.bool,
};

IllustratorChart.defaultValues = {
  isAnimationActive: true,
};

export default IllustratorChart;
