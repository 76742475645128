import {API_ROUTES} from 'constants/illustrator';
import {useOwnFormContext} from 'contexts/form-context';
import {ZipField} from 'lib/validation/validation';
import {isFunction} from 'lodash';
import {useJumboRateValidationQuery} from 'query/validations/jumbo-rate';
import {useCallback, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

const zipValidation = ZipField();

export function useIsJumboRate() {
  const {validationSchema} = useOwnFormContext();

  return useMemo(
    () => !!validationSchema?.fields?.BorrowerProfile?.fields?.NumberOfUnits,
    [validationSchema?.fields?.BorrowerProfile?.fields?.NumberOfUnits],
  );
}

export function useJumboRateWatch(props = {}) {
  const {withApiRoute = true} = props;
  const {setFormContext, resetFormContext, extraArguments} = useOwnFormContext();
  const {watch} = useFormContext();

  const [Zip, ApiRoute] = watch(['BorrowerProfile.Zip', 'ApiRoute']);

  const jumboRateEnabled = useMemo(() => {
    try {
      zipValidation.validateSync(Zip);

      if (withApiRoute ? ApiRoute === API_ROUTES.HYBRID : true) {
        return true;
      }
    } catch (e) {}
    return false;
  }, [Zip, ApiRoute, withApiRoute]);

  const onSetFormContext = useCallback(() => {
    if (isFunction(setFormContext)) setFormContext(extraArguments);
  }, [setFormContext, extraArguments]);

  const onResetFormContext = useCallback(() => {
    if (isFunction(resetFormContext)) resetFormContext();
  }, [resetFormContext]);

  useJumboRateValidationQuery({
    Zip,
    enabled: jumboRateEnabled,
    onSuccess() {
      onSetFormContext();
    },
    onError() {
      onResetFormContext();
    },
  });
}
