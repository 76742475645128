import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    notesListContainer: {
      width: '100%',
      margin: 0,
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
    },
    notesListItem: {
      margin: 0,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    noteLabel: {
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
    notesListInfo: {
      marginLeft: theme.forPrint(theme.spacing(3)),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 0,
      marginTop: 0,
      marginBottom: 0
    },
  }),
  {index: 1},
);

export default useStyles;
