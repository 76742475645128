import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

function ScrollToTop({history, children}) {
  useEffect(() => {
    const listener = history.listen(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    });
    return () => {
      listener();
    };
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);
