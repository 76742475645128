import clsx from 'clsx';
import Button from 'components/common/button/Button';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {useStepWizardContext} from 'contexts/step-wizard';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {useTranslation} from 'lib/i18n';
import useStyles from 'pages/illustrator-borrower/broker-transaction-question/BrokerTransactionQuestionStyles';
import StepElement from 'pages/illustrator-borrower/components/step-element/StepElement';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {updateSelectedIllustratorBorrower} from 'reducers/illustrator-borrower.reducer';

function BrokerTransactionQuestion(props) {
  const {nextStep, skipNextStep} = useStepWizardContext();

  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  /**
   *
   * on click "YES" handler
   *
   */

  const onClickYesHandler = useCallback(async () => {
    /**
     *
     * the next step will always be : broker transactions
     *
     * the "broker transaction question" and "broker transactions" are a group
     * if one page is active, both pages are active
     * if one page is inactive, both pages are inactive
     *
     */

    await dispatch(
      updateSelectedIllustratorBorrower({
        BrokerOffersForwardMortgageProducts: 'Yes',
      }),
    );

    return nextStep();
  }, [dispatch, nextStep]);

  /**
   *
   * on click "NO" handler
   *
   */

  const {isEnabled: isEnableBrokerTransactionQuestion} = useEnabledProductFeatures(
    PRODUCT_FEATURE_KEY.BORROW_BROKER_TRANSACTIONS,
  );

  const onClickNoHandler = useCallback(async () => {
    if (!isEnableBrokerTransactionQuestion) return nextStep();

    // make sure that the old data gets deleted
    await dispatch(
      updateSelectedIllustratorBorrower({
        BrokerOffersForwardMortgageProducts: 'No',
        brokerTransactions: {
          // section #1
          totalInitialRate1: '',
          totalFeesPoints1: '',
          // section #2
          totalInitialRate2: '',
          totalFeesPoints2: '',
          // section #3
          totalInitialRate3: '',
          totalFeesPoints3: '',
        },
      }),
    );

    /**
     *
     * the user wants to skip the "broker transactions"
     *
     * the user will land on the next page, right after the "broker transactions"
     *
     */

    return skipNextStep();
  }, [nextStep, dispatch, skipNextStep, isEnableBrokerTransactionQuestion]);

  return (
    <StepElement
      id="brokerTransactionQuestion"
      data-testid="broker-transaction-question"
      title={t('illustratorTranslation:illustrator borrower broker transaction question.title')}
    >
      <Container className={classes.buttonContainer}>
        <Item xs={12} className={clsx(classes.subtitleItem)}>
          <Title variant="subtitle1">
            {t('illustratorTranslation:illustrator borrower broker transactions.subTitle')}
          </Title>
        </Item>

        <Item xs={12} sm={5} md={4} xl={3}>
          <Button
            btnStyle="bgElectric"
            buttonStyling={{lg: true}}
            onClick={onClickYesHandler}
            data-testid="broker-transaction-question-yes-button"
          >
            {t('translation:common.yes')}
          </Button>
        </Item>

        <Item xs={12} sm={5} md={4} xl={3}>
          <Button
            btnStyle="bgElectric"
            buttonStyling={{lg: true}}
            onClick={onClickNoHandler}
            data-testid="broker-transaction-question-no-button"
          >
            {t('translation:common.no')}
          </Button>
        </Item>
      </Container>
    </StepElement>
  );
}

BrokerTransactionQuestion.propTypes = {};

BrokerTransactionQuestion.defaultValues = {};

export default BrokerTransactionQuestion;
