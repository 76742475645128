import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isPending, statusToLoading} from 'adapter/loading.adapter';
import clsx from 'clsx';
import Button from 'components/common/button/Button';
import Form from 'components/common/form/Form';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import LinearLoader from 'components/common/loader/LinearLoader';
import Text from 'components/common/typeography/text/Text';
import FullScreenForScreenShot from 'components/illustrator/modal-email-to-borrower/components/preview-pdf/components/full-screen-for-screenshot/FullScreenForScreenShot';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/preview-pdf/PreviewPdfStyles';
import {LOADING_STATUS} from 'constants/loading';
import {useDialogStateMutation, withDialogState} from 'hooks/dialog';
import {useDeepMemo} from 'hooks/utils';
import {useTranslation} from 'lib/i18n';
import {usePdfWithPreviewQuery} from 'query/illustrator/get-preview-pdf';
import {useEffect, useMemo, useState} from 'react';
import {useDeepCompareEffect} from 'react-use';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from '../../../../../constants/analytic-events';
import {trackEvent} from '../../../../../services/analytics.service';

/**
 *
 * "Preview PDF" component
 *
 *    parent component : modal "Email to Borrower"
 *

 */
function PreviewPdf(props = {}) {
  // props
  const {
    state,
    nextStep, // f()
    setState,
  } = props;

  useEffect(() => {
    setState((preState) => ({...preState, closeDialogAndSendAnalyticsEvent: false}));
  }, [setState]);

  const classes = useStyles();
  const {t} = useTranslation();

  const screenshots = usePdfWithPreviewQuery({
    illustrations: state?.illustrationsToShare,
    select(response) {
      return response;
    },
  });

  const screenShotsLoading = useMemo(
    () => screenshots.map((s) => statusToLoading(s.status, s.isFetching)).find((loading) => isPending({loading})),
    [screenshots],
  );

  const pdfs = useDeepMemo(() => screenshots.map((s) => s.data), [screenshots]);

  useDeepCompareEffect(() => {
    if (!screenShotsLoading && pdfs.length) {
      setState((oldState) => ({...oldState, pdfs}));
    }
  }, [screenShotsLoading, pdfs]);

  const handleContinue = () => {
    trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_PREVIEW_PDF);
    return nextStep();
  };

  const [fullScreenForScreenshot, setFullScreenForScreenshot] = useState(false);
  const [imageId, setImageId] = useState(null);

  const handleFullScreenForScreenshotSetTrue = (index) => {
    setFullScreenForScreenshot(true);
    setImageId(index);
  };

  const handleFullScreenForScreenshotSetFalse = () => {
    setFullScreenForScreenshot(false);
  };

  useDialogStateMutation({setState, buttonNextDisabled: !!screenShotsLoading, buttonNextProgress: false});

  return (
    <Form onSubmit={handleContinue} id={state.formId}>
      <FullScreenForScreenShot
        state={fullScreenForScreenshot}
        setState={handleFullScreenForScreenshotSetFalse}
        image={screenshots[imageId]?.data?.image}
      />

      <Container className={clsx(classes.itemContainer, classes.previewPDFContainer)}>
        <Item xs={12}>
          <Text className={clsx(classes.textAlignCenter, classes.subTitle)}>
            {t('illustratorTranslation:modal email to borrower.preview pdf.subtitle')}
          </Text>
        </Item>

        {screenShotsLoading ? (
          <Item xs={12}>
            <Text className={classes.textAlignCenter}>{t('translation:common.data is being loaded please wait')}</Text>
            <div style={{width: '100%'}}>
              <LinearLoader loading={LOADING_STATUS.PENDING} />
            </div>
          </Item>
        ) : (
          <Item xs={12}>
            <Container className={classes.screenShotContainer}>
              {screenshots.map((screenshot, index) => (
                <Item key={index} className={classes.screenShotElement}>
                  <div className={classes.imageContainer}>
                    <img
                      src={screenshot.data?.image}
                      onClick={() => handleFullScreenForScreenshotSetTrue(index)}
                      alt={'PDF Preview'}
                      className={classes.previewPdf}
                    />
                  </div>

                  <div className={classes.textAlignCenter}>
                    <Button
                      variant="text"
                      onClick={() => {
                        handleFullScreenForScreenshotSetTrue(index);
                      }}
                    >
                      <span>{t('translation:common.expand')}</span>
                      <span>
                        &nbsp;
                        <FontAwesomeIcon icon={['fa', 'expand']} />
                      </span>
                    </Button>
                  </div>
                </Item>
              ))}
            </Container>
          </Item>
        )}
      </Container>
    </Form>
  );
}

export default withDialogState(PreviewPdf, {
  title: 'illustratorTranslation:modal email to borrower.preview pdf.title',
  formId: 'add-borrower-notes',
  buttonReturn: '',
});
