import {QueryClient} from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      select: (response) => response.data,
      staleTime: Infinity,
      cacheTime: 5 * 60 * 1000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
    mutations: {
      retry: false,
    },
  },
});

/**
 *
 * @param {FetchQueryOptions} options
 * @returns {Promise<void>}
 */
export function prefetchQuery(options) {
  return queryClient.prefetchQuery(options);
}
