import {useCallback, useMemo} from 'react';

import {isLoaded, statusToLoading} from 'adapter/loading.adapter';
import {API_ROUTES} from 'constants/illustrator';
import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {productsComparisonQueryKey, productsQueryKey} from 'constants/query';
import {useProductContext} from 'contexts/product-context';
import {useEnabledProductFeatures} from 'hooks/product-features';
import {useQueryProducts} from 'hooks/query-products';
import {VIEW_TYPE} from 'hooks/view-type';
import {useSkipBorrowerAdditionalStep} from 'lib/borrower';
import {generateId} from 'lib/generator';
import {Trans} from 'lib/i18n';
import {excludeForwardRefi, isTenurePayment} from 'lib/product';
import {ToNumberOrDefault} from 'lib/utils';
import {defaultsDeep, isFunction} from 'lodash';
import {useProductsQuery} from 'query/products';
import {useQueryClient} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {cleanUpIllustration, getSelectedIllustration, updateSelectedIllustration} from 'reducers/illustrator.reducer';
import {setBreakDownViewType} from 'reducers/settings.reducer';
import {trackEvent} from '.././services/analytics.service';
import {PRODUCT_ACTIONS, PRODUCT_CATEGORIES} from '../constants/analytic-events';

export function useGetProducts(props = {}) {
  const {data: products = [], status, isFetching} = useProductsQuery(props);

  return {products, loading: statusToLoading(status, isFetching)};
}

export function useIllustrateProduct(props = {}) {
  const {onTabChange, onProductSelected, isProductComparsion, setHasSubmitted} = props;

  const selectedIllustration = useSelector(getSelectedIllustration);
  const dispatch = useDispatch();

  const illustrateProduct = useCallback(
    async (product, FundAccess = selectedIllustration.FundAccess, Tenure = selectedIllustration.Tenure, event) => {
      if (isProductComparsion) {
        trackEvent(PRODUCT_CATEGORIES[product.ApiRoute], PRODUCT_ACTIONS.PROD_SELECT_COMPARE);
      } else {
        trackEvent(PRODUCT_CATEGORIES[product.ApiRoute], PRODUCT_ACTIONS.PROD_SELECT_RESULTS);
        setHasSubmitted(true);
      }

      const newIllustration = defaultsDeep({...product?.calculation}, {...cleanUpIllustration(selectedIllustration)});

      const illustration = {
        ...newIllustration,
        FundAccess: FundAccess || newIllustration?.FundAccess,
        tenurePayment: isTenurePayment({Tenure}),
        CalculationId: '',
        // feature to hide the illustration form prompt navigation attempt modal
        navigationAttemptModal: product?.navigationAttemptModal,
      };

      dispatch(setBreakDownViewType());
      dispatch(updateSelectedIllustration(illustration));

      const isPropagationStopped = event?.isPropagationStopped();
      if (!isPropagationStopped && isFunction(onProductSelected)) onProductSelected(product);
      if (!isPropagationStopped && isFunction(onTabChange)) onTabChange(product, VIEW_TYPE.illustrate);

      return illustration;
    },
    [selectedIllustration, isProductComparsion, dispatch, onProductSelected, onTabChange, setHasSubmitted],
  );

  return {illustrateProduct};
}

export function useExistingMortgageProduct(props) {
  const {products = [], loading} = useQueryProducts();

  const {isEnabled: isEnabledExistingMortgage} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.EXISTING_MORTGAGE_WIZ);

  const product = useMemo(() => (isLoaded({loading}) ? products.find(Boolean) : null) ?? {}, [products, loading]);

  const ExistingMortgageProduct = useMemo(() => {
    if (product?.calculation?.BorrowerProfile?.ExistingMortgage && isEnabledExistingMortgage) {
      const {MortgagePayment, YearsRemaining, MortgageRate} = product.calculation.BorrowerProfile.ExistingMortgage;
      const NewMonthlyPayment = ToNumberOrDefault(MortgagePayment, 'N/A');
      const PaymentTermYears = ToNumberOrDefault(YearsRemaining, 'N/A');
      const TotalInitialRate = ToNumberOrDefault(MortgageRate, 'N/A');

      if (!isNaN(NewMonthlyPayment) || !isNaN(PaymentTermYears) || !isNaN(TotalInitialRate)) {
        return {
          ...product,
          ApiRoute: API_ROUTES.EXISTING_MORTGAGE,
          ProductId: generateId(),
          ProductName: <Trans i18nKey="illustratorTranslation:illustration.products.existing mortgage.title" />,
          calculation: {
            ...product?.calculation,
            CalculationId: generateId(),
            hideModelImpactButton: true,
            SimplifiedCashToBorrower: 'N/A',
            PrincipalLimit: 'N/A',
            ClosingCost: 'N/A',
            HybridMetric: {PaymentTermYears},
            HybridResult: {NewMonthlyPayment},
            TotalInitialRate,
            RemainingEquity: 'N/A',
            recommended: false,
          },
        };
      }
    }

    return null;
  }, [product, isEnabledExistingMortgage]);

  return {ExistingMortgageProduct};
}

export function useProductsToRecommend() {
  const queryClient = useQueryClient();

  const queryStateProducts = queryClient.getQueryState([productsQueryKey], {exact: false, active: true});

  const loadingProducts = statusToLoading(queryStateProducts?.status, queryStateProducts?.isFetching);

  const products = useMemo(() => {
    const queryData = queryClient.getQueryData([productsQueryKey], {exact: false, active: true});

    if (isLoaded({loading: loadingProducts})) {
      return queryData?.data.filter(excludeForwardRefi);
    }
    return [];
  }, [queryClient, loadingProducts]);

  const queryStateProductsComparison = queryClient.getQueryState([productsComparisonQueryKey], {
    exact: false,
    active: true,
  });
  const loadingProductsComparison = statusToLoading(
    queryStateProductsComparison?.status,
    queryStateProductsComparison?.isFetching,
  );

  return {products, enabled: isLoaded({loading: loadingProductsComparison})};
}

export function useSkipProductAdditionalStep() {
  const {productDetails: {additionalStep, skipAdditionalStep} = {}} = useProductContext();

  const {shouldSkip: skipBorrowerAdditionalStep} = useSkipBorrowerAdditionalStep({additionalStep, skipAdditionalStep});

  return {skipBorrowerAdditionalStep};
}
