import {Paper} from '@material-ui/core';
import clsx from 'clsx';
import Form from 'components/common/form/Form';
import Input from 'components/common/form/input/Input';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import IllustrationExtraDetails from 'components/illustrator/illustration-extra-details/IllustrationExtraDetails';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/add-borrower-notes/AddBorrowerNotesStyles';
import {withDialogState} from 'hooks/dialog';
import {useIllustrationProduct} from 'hooks/illustration-product';
import {useTranslation} from 'lib/i18n';
import {useSaveBorrowerNotesMutation} from 'query/borrower/save-borrower-notes';
import {useCallback, useEffect} from 'react';
import {SCENARIO_ACTIONS, SCENARIO_CATEGORIES} from '../../../../../constants/analytic-events';
import {trackEvent} from '../../../../../services/analytics.service';

/**
 *
 * "Add Borrower Notes" component
 *
 *    parent component : modal "Email to Borrower"
 *
 */

function AddBorrowerNotes(props = {}) {
  // props
  const {
    state,
    setState,
    nextStep, // f()
  } = props;

  useEffect(() => {
    setState((preState) => ({...preState, closeDialogAndSendAnalyticsEvent: false}));
  }, [setState]);

  const {t} = useTranslation();
  const classes = useStyles();

  const saveBorrowerNotesMutation = useSaveBorrowerNotesMutation();

  const {getProduct} = useIllustrationProduct();

  const doSubmit = useCallback(
    async (data) => {
      trackEvent(SCENARIO_CATEGORIES.DEFAULT, SCENARIO_ACTIONS.SCENARIO_EMAIL_TO_BORROWER_ADD_NOTES);

      const calculationsKey = Object.keys(data);

      calculationsKey.map(async (key) => {
        const note = data[key];

        const illustrationToShare = state?.illustrationsToShare?.find(
          (illustration) => illustration?.CalculationId === key,
        );

        if (
          illustrationToShare !== 'undefined' &&
          illustrationToShare?.CalculationId?.length &&
          illustrationToShare?.CalculationId === key &&
          note?.length
        ) {
          let result = await saveBorrowerNotesMutation.mutateAsync({
            notes: note,
            CalculationId: key,
          });

          if (result.status === 200) {
            let notes = state.notes;

            notes[key] = note;

            setState((oldState) => ({
              ...oldState,
              notes: notes,
            }));
          }
        }

        return key;
      });

      return nextStep();
    },
    [nextStep, saveBorrowerNotesMutation, setState, state?.illustrationsToShare, state.notes],
  );

  return (
    <Form onSubmit={doSubmit} id={state.formId}>
      {state.illustrationsToShare.map((illustrationToShare, index) => {
        const product = getProduct(illustrationToShare);

        return (
          <Container spacing={2} key={index}>
            <Paper variant="outlined" square className={clsx(classes.paper, classes.m2)}>
              <Item xs={12} className={classes.m2}>
                <h2>{product?.ProductName}</h2>
              </Item>

              <Item xs={12} className={classes.m2}>
                <IllustrationExtraDetails className={classes.mb2} illustration={illustrationToShare} />

                <Container>
                  <Item xs={12}>
                    <Input
                      type="textArea"
                      name={illustrationToShare.CalculationId}
                      defaultValue={state.notes[illustrationToShare.CalculationId] || ''}
                      variant="outlined"
                      multiline={true}
                      minRows={3}
                      maxRows={5}
                      fullWidth={true}
                      placeholder={t('borrowerNotesModalTranslation:add upToChars')}
                    />
                  </Item>
                </Container>
              </Item>
            </Paper>
          </Container>
        );
      })}
    </Form>
  );
}

AddBorrowerNotes.propTypes = {};

AddBorrowerNotes.defaultValues = {};

export default withDialogState(AddBorrowerNotes, {
  title: 'illustratorTranslation:modal email to borrower.add borrower notes.title',
  formId: 'add-borrower-notes',
  buttonReturn: '',
});
