import clsx from 'clsx';
import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import Item from 'components/common/layout/item/Item';
import Title from 'components/common/typeography/title/Title';
import {useIllustrationContext} from 'contexts/illustration-context';
import {useTranslation} from 'lib/i18n';
import {isTenurePayment} from 'lib/product';
import {timeOut} from 'lib/utils';
import {isBoolean} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import useStyles from './PaymentAtLaterAgeStyles';

function usePaymentAtLaterAge(defaultAge = 0) {
  const {watch, setValue} = useFormContext();

  const [Tenure, ConversionAge = 0] = watch(['Tenure', 'TenureResult.ConversionAge']);

  const illustration = useIllustrationContext();
  const formid = `illustration-${illustration.id}`;

  const triggerSubmit = useCallback(async () => {
    return new Promise(async (resolve) => {
      await timeOut(200);

      const formButton = document.querySelector(`form[id="${formid}"] button[type="submit"]`);
      formButton.click();

      return resolve();
    });
  }, [formid]);

  const togglePaymentAtLaterAge = useCallback(
    (isOpen) => {
      const data = {};

      if (isOpen) {
        data.Tenure = true;
        if (!ConversionAge) {
          data['TenureResult.ConversionAge'] = defaultAge;
        }
      } else {
        data.Tenure = false;
      }

      Object.keys(data).map((key) => setValue(key, data[key], {shouldDirty: false}));
      triggerSubmit();
    },
    [defaultAge, setValue, ConversionAge, triggerSubmit],
  );

  return {tenurePayment: useMemo(() => isTenurePayment({Tenure}), [Tenure]), togglePaymentAtLaterAge};
}

function PaymentAtLaterAge(props) {
  const {autoSubmit, order, defaultAge, disabled} = props;
  const hideStep = isBoolean(autoSubmit) ? !autoSubmit : false;

  const classes = useStyles();
  const {tenurePayment, togglePaymentAtLaterAge} = usePaymentAtLaterAge(defaultAge);
  const {t} = useTranslation();

  return (
    <>
      <Switch
        hidden={hideStep}
        order={order}
        name="tenurePayment"
        value={tenurePayment}
        onChange={togglePaymentAtLaterAge}
        disabled={disabled}
      />
      <Input type="hidden" name="id" />

      <Item
        className={clsx(classes.paymentAtLaterAgeContainer, {
          [classes.hide]: hideStep || !tenurePayment,
        })}
      >
        <Title variant="h6" className="strong" colorVariant="black">
          {t('illustratorTranslation:illustration.products.annual.payment at later age.title')}
        </Title>

        <Item className={classes.item}>
          <Input name="TenureResult.ConversionAge" type="number" />
        </Item>
        <Item className={classes.item}>
          <Input name="TenureResult.MaxMonthlyPayment" type="currency" disabled />
        </Item>
      </Item>
    </>
  );
}

PaymentAtLaterAge.propTypes = {};

export default React.memo(PaymentAtLaterAge);
