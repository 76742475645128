import Input from 'components/common/form/input/Input';
import InRangeSelect from 'components/common/form/select/InRangeSelect';
import Select from 'components/common/form/select/Select';
import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import RenderAllAdditionalOptions from 'components/common/render-all-additional-options/render-all-additional-options';
import Title from 'components/common/typeography/title/Title';
import {useProductContext} from 'contexts/product-context';
import useNumberOfUnitsOptions from 'hooks/link-application/property-taxes/number-of-units';
import usePropertyTypeOptions, {PROPERTY_TYPE} from 'hooks/link-application/property-taxes/property-type';
import {useTranslation} from 'lib/i18n';
import useStyles from './PropertyTaxesFormElementsStyles';

function PropertyTaxesFormElements() {
  const classes = useStyles();
  const {t} = useTranslation();

  const {productDetails: {additionalBorrowerFlowPropertyPageInputs = []} = {}} = useProductContext();

  const propertyTypeOptions = usePropertyTypeOptions();
  const numberOfUnitsOptions = useNumberOfUnitsOptions();

  return (
    <>
      <Container>
        <Item xs={12}>
          <Title variant="h5" style={{textAlign: 'left'}}>
            {t('illustratorTranslation:illustrator borrower property details.section')}
          </Title>
        </Item>

        <Item xs={12} sm={6}>
          <Input name="propertyHomeValue" variant="filled" type="currency" />
        </Item>
      </Container>
      <Container>
        <Item xs={12} sm={6}>
          <Select
            order="1"
            name={PROPERTY_TYPE}
            variant="filled"
            className={classes.customSelectElement}
            options={propertyTypeOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
          />
        </Item>

        <Item xs={12} sm={6}>
          <InRangeSelect
            order="1"
            name="NumberOfUnits"
            variant="filled"
            className={classes.customSelectElement}
            options={numberOfUnitsOptions}
          />
        </Item>
        <Item xs={12} sm={6}>
          <Input
            name="propertyMortgagePayoff"
            variant="filled"
            type="currency"
            toolTipText={t('inputsTranslation:propertyMortgagePayoff.label extra description')}
          />
        </Item>

        <RenderAllAdditionalOptions options={additionalBorrowerFlowPropertyPageInputs} sm={6} />
      </Container>
    </>
  );
}

PropertyTaxesFormElements.propTypes = {};

export default PropertyTaxesFormElements;
