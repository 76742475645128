import {useAuthenticated} from 'hooks/authenticate';
import {useEffect} from 'react';
import {oktaAuth} from 'services/auth.service';

const docuSignConfig = {
  env: window.env.REACT_APP_DOCUSIGN_ENVIRONMENT,
  accountId: window.env.REACT_APP_DOCUSIGN_ACCOUNT_ID,
  clickwrapId: window.env.REACT_APP_DOCUSIGN_CLICKWRAP_ID,
};

function TermsOfService(props) {
  const {isAuthenticated} = useAuthenticated();

  function renderClickwrap(email) {
    // docuSignClick is defined in a public js file and does not have a npm package
    // disabling eslint checking now with the hopes of a package in the future.
    // eslint-disable-next-line no-undef
    docuSignClick.Clickwrap.render(
      {
        environment: docuSignConfig.env,
        accountId: docuSignConfig.accountId,
        clickwrapId: docuSignConfig.clickwrapId,
        clientUserId: email,
      },
      '#ds-clickwrap',
    );
  }

  useEffect(() => {
    (async function () {
      if (props.test) {
        renderClickwrap(props.email);
        return;
      }

      if (!isAuthenticated) {
        return;
      }

      const user = await oktaAuth.token.getUserInfo();

      if (!user || !user.email) {
        return;
      }

      renderClickwrap(user.email);
    })();
  }, [isAuthenticated, props.email, props.test]);

  return <div id="ds-clickwrap"></div>;
}

export default TermsOfService;
