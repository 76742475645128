import {FUND_ACCESS} from 'constants/illustrator';
import {getBorrowerBirthDate} from 'lib/borrower';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {formatPaymentBreakdownValue, isPaymentBreakdownValueNegative} from 'lib/product';
import {parentheses} from 'lib/utils';
import {useMemo} from 'react';

export function useAnnualOverallResults(props = {}) {
  const {
    product: {
      ApiRoute,
      calculation: {
        BorrowerProfile: {Age, DateOfBirth, Liens, ValueOfProperty} = {},
        PrincipalLimit,
        ClosingCost,
        CashAtClose,
        FundAccess,
        Margin,
        TenureResult: {MaxMonthlyPayment, MonthlyTenurePaymentFirstYear, ConversionAge} = {},
        Tenure,
        ExpectedRate,
        InitialRate,
        MIP,
        MonthlyHUDTotalDrawLessInitialLoanBalance,
        TotalInitialRate,
        SimplifiedCashToBorrower,
        InitialLoanBalance,
        InitialCashDraw,
        LineOfCredit,
        TotalCashProceeds,
        HUDTotalDraw,
      } = {},
    } = {},
  } = props;
  const {t, cashLabel, override} = useTranslation();

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const isTenureWithAge = useMemo(() => {
    if (ConversionAge > Age && Tenure) {
      return true;
    } else if (!Tenure) {
      return true;
    } else {
      return false;
    }
  }, [ConversionAge, Tenure, Age]);

  const tenureYears = useMemo(() => {
    return ConversionAge - Age;
  }, [ConversionAge, Age]);

  const isMonthlyTenurePaymentFirstYear = useMemo(() => {
    if(tenureYears > 0){
      return false;
    }
    else if (MonthlyHUDTotalDrawLessInitialLoanBalance < MaxMonthlyPayment && MonthlyTenurePaymentFirstYear > 0) {
      return true;
    } else if (MonthlyHUDTotalDrawLessInitialLoanBalance === MaxMonthlyPayment) {
      return false;
    } else {
      return MonthlyTenurePaymentFirstYear > 0 &&
        MonthlyTenurePaymentFirstYear !== MaxMonthlyPayment &&
        FundAccess !== FUND_ACCESS.LUMP_SUM
        ? true
        : false;
    }
  }, [MonthlyHUDTotalDrawLessInitialLoanBalance, MaxMonthlyPayment, MonthlyTenurePaymentFirstYear, FundAccess, tenureYears]);

  const monthlyTenurePaymentTooltip = useMemo(() => {

    if(FundAccess === FUND_ACCESS.LINE_OF_CREDIT){
      if(isMonthlyTenurePaymentFirstYear){
        return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment full loc cash at close tooltip';
      } else {
        if (tenureYears === 0) {
          if (HUDTotalDraw === InitialLoanBalance) {
            return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment loc large cash at close tooltip';
          } else if (MonthlyHUDTotalDrawLessInitialLoanBalance < MaxMonthlyPayment) {
            return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment full loc cash at close tooltip';
          } else {
            return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment loc tooltip';
          }
        } else if (tenureYears === 1) {
          return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment loc large cash at close tooltip';
        } else {
          return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment loc conversion age greater than one tooltip';
        }
      }
    } else {
      if (isTenureWithAge) {
        return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment lumpsum older age tooltip';
      } else {
        return 'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment lumpsum tooltip';
      }
    }
  }, [
    FundAccess,
    isMonthlyTenurePaymentFirstYear,
    tenureYears,
    HUDTotalDraw,
    InitialLoanBalance,
    MonthlyHUDTotalDrawLessInitialLoanBalance,
    MaxMonthlyPayment,
    isTenureWithAge,
  ]);

  const availableCashSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.total proceeds'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.annual.total proceeds tooltip'),
        value: formatCurrency(PrincipalLimit),
        order: 2,
        id: 'availableCashSection-total-proceeds',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.total mortgages liens'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.annual.total mortgages liens tooltip'),
        value: parentheses(formatCurrency(Liens)),
        useColorOptions: {value: 'red'},
        order: 3,
        id: 'availableCashSection-total-mortgage-balance-liens',
        highlight: false,
      },
      {
        label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
        tooltip: t(
          'illustratorTranslation:illustration.overall results.override.annual.estimated closing costs tooltip',
        ),
        value: parentheses(formatCurrency(ClosingCost)),
        useColorOptions: {value: 'red'},
        order: 4,
        id: 'availableCashSection-estimated-closing-cost',
        highlight: false,
      },
      {
        label: cashLabel(
          isShortToClose
            ? 'illustratorTranslation:illustration.overall results.cash due at close'
            : 'illustratorTranslation:illustration.overall results.available cash',
          {
            cash: CashAtClose,
          },
        ),
        tooltip: t(
          isShortToClose
            ? 'illustratorTranslation:illustration.overall results.override.annual.cash due at close tooltip'
            : 'illustratorTranslation:illustration.overall results.override.annual.available cash tooltip',
        ),
        value: formatPaymentBreakdownValue(isShortToClose ? SimplifiedCashToBorrower : TotalCashProceeds),
        useColorOptions: {
          value: isPaymentBreakdownValueNegative(isShortToClose ? SimplifiedCashToBorrower : TotalCashProceeds)
            ? 'red'
            : undefined,
        },
        order: 5,
        id: 'availableCashSection-available-cash',
        highlight: true,
      },
    ],
    [
      t,
      PrincipalLimit,
      Liens,
      ClosingCost,
      cashLabel,
      isShortToClose,
      CashAtClose,
      SimplifiedCashToBorrower,
      TotalCashProceeds,
    ],
  );

  const initialLoanBalanceSection = useMemo(
    () =>
      [
        {
          label: override('illustratorTranslation:illustration.overall results.mortgage liens payoff', {
            product: {ApiRoute},
          }),
          tooltip: override(
            'illustratorTranslation:illustration.overall results.override.annual.mortgage liens payoff tooltip',
            {
              product: {ApiRoute},
            },
          ),
          value: formatCurrency(Liens),
          order: 2,
          id: 'initialLoanBalanceSection-mortgage-liens-payoff',
          highlight: false,
        },
        {
          label: t('illustratorTranslation:illustration.overall results.estimated closing costs'),
          tooltip: t(
            'illustratorTranslation:illustration.overall results.override.annual.estimated closing costs tooltip',
          ),
          value: formatCurrency(ClosingCost),
          order: 3,
          id: 'initialLoanBalanceSection-estimated-closing-cost',
          highlight: false,
        },
        {
          label: cashLabel(
            isShortToClose
              ? 'illustratorTranslation:illustration.overall results.cash due at close'
              : 'illustratorTranslation:illustration.overall results.initial cash draw',
            {
              cash: CashAtClose,
            },
          ),
          tooltip: override(
            isShortToClose
              ? 'illustratorTranslation:illustration.overall results.override.annual.cash due at close tooltip'
              : 'illustratorTranslation:illustration.overall results.override.annual.initial cash draw tooltip',
            {
              product: {ApiRoute},
            },
          ),
          value: formatPaymentBreakdownValue(InitialCashDraw),
          useColorOptions: {value: isPaymentBreakdownValueNegative(SimplifiedCashToBorrower) ? 'red' : undefined},
          order: 4,
          id: 'initialLoanBalanceSection-initial-cash-draw',
          highlight: false,
        },
        {
          label: t('illustratorTranslation:illustration.overall results.initial loan balance'),
          tooltip: override(
            'illustratorTranslation:illustration.overall results.override.annual.initial loan balance tooltip',
            {
              product: {ApiRoute},
            },
          ),
          value: formatCurrency(InitialLoanBalance),
          order: 5,
          id: 'initialLoanBalanceSection-initial-loan-balance',
          highlight: true,
        },
        {
          label: t('illustratorTranslation:illustration.overall results.available loc'),
          tooltip: override(
            isTenureWithAge && Tenure
              ? 'illustratorTranslation:illustration.overall results.override.annual.available loc tenure tooltip'
              : 'illustratorTranslation:illustration.overall results.override.annual.available loc tooltip',
            {
              product: {ApiRoute},
            },
          ),
          value: formatCurrency(LineOfCredit),
          order: 6,
          id: 'initialLoanBalanceSection-available-loc',
          highlight: false,
          hide: !isTenureWithAge,
        },
        {
          label: t('illustratorTranslation:illustration.overall results.monthly tenure payment first year'),
          tooltip: override(
            'illustratorTranslation:illustration.overall results.override.annual.monthly tenure payment first year tooltip',
            {
              product: {ApiRoute},
            },
          ),
          value: formatCurrency(MonthlyTenurePaymentFirstYear),
          order: 6,
          id: 'initialLoanBalanceSection-monthly-tenure-payment-first-year',
          highlight: false,
          hide: !Tenure || !isMonthlyTenurePaymentFirstYear,
        },
        {
          label: t('illustratorTranslation:illustration.overall results.monthly tenure payment'),
          tooltip: override(monthlyTenurePaymentTooltip, {
            years: tenureYears,
          }),
          value: formatCurrency(MaxMonthlyPayment),
          order: 6,
          id: 'initialLoanBalanceSection-monthly-tenure-payment',
          highlight: false,
          hide: !Tenure,
        },
      ].filter((element) => !element.hide),
    [
      override,
      ApiRoute,
      Liens,
      t,
      ClosingCost,
      cashLabel,
      isShortToClose,
      CashAtClose,
      InitialCashDraw,
      SimplifiedCashToBorrower,
      InitialLoanBalance,
      isTenureWithAge,
      Tenure,
      LineOfCredit,
      MonthlyTenurePaymentFirstYear,
      isMonthlyTenurePaymentFirstYear,
      monthlyTenurePaymentTooltip,
      tenureYears,
      MaxMonthlyPayment,
    ],
  );

  const homeInformationSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.estimated home value'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.annual.est home value tooltip'),
        value: formatCurrency(ValueOfProperty),
        order: 2,
        id: 'homeInformationSection-estimated-home-value',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.current mortgage liens'),
        tooltip: t(
          'illustratorTranslation:illustration.overall results.override.annual.current mortgage liens tooltip',
        ),
        value: formatCurrency(Liens),
        order: 3,
        id: 'homeInformationSection-current-mortgage-liens',
      },
    ],
    [t, ValueOfProperty, Liens],
  );

  const youngestBorrowerSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.birth date'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.annual.birthdate tooltip'),
        value: getBorrowerBirthDate(DateOfBirth),
        order: 2,
        id: 'youngestBorrowerSection-birth-date',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.nearest age'),
        tooltip: t('illustratorTranslation:illustration.overall results.override.annual.nearest age tooltip'),
        value: Age,
        order: 3,
        id: 'youngestBorrowerSection-nearest-age',
      },
    ],
    [t, DateOfBirth, Age],
  );

  const loanDetailsSection = useMemo(
    () => [
      {
        label: t('illustratorTranslation:illustration.overall results.margin'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.annual.margin tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(Margin),
        order: 2,
        id: 'loanDetailsSection-margin',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.expected rate'),
        tooltip: override('illustratorTranslation:illustration.overall results.override.annual.expected rate tooltip', {
          product: {ApiRoute},
        }),
        value: formatPercent(ExpectedRate),
        order: 2,
        id: 'loanDetailsSection-expected-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial interest rate'),
        tooltip: override(
          'illustratorTranslation:illustration.overall results.override.annual.initial interest rate tooltip',
          {
            product: {ApiRoute},
          },
        ),
        value: formatPercent(InitialRate),
        order: 2,
        id: 'loanDetailsSection-initial-interest-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.mip'),
        tooltip: t(
          'illustratorTranslation:illustration.overall results.override.annual.mortgage insurance premium tooltip',
        ),
        value: formatPercent(MIP),
        order: 3,
        id: 'loanDetailsSection-mip',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.total accrual rate'),
        tooltip: override(
          'illustratorTranslation:illustration.overall results.override.annual.total accrual rate tooltip',
          {
            product: {ApiRoute},
          },
        ),
        value: formatPercent(TotalInitialRate),
        order: 2,
        id: 'loanDetailsSection-total-accrual-rate',
      },
      {
        label: t('illustratorTranslation:illustration.overall results.initial loc growth rate'),
        tooltip: override(
          'illustratorTranslation:illustration.overall results.override.annual.initial loc growth rate tooltip',
          {
            product: {ApiRoute},
          },
        ),
        value: formatPercent(TotalInitialRate),
        order: 2,
        id: 'loanDetailsSection-initial-loc-growth-rate',
      },
    ],
    [t, override, ApiRoute, MIP, TotalInitialRate, ExpectedRate, InitialRate, Margin],
  );

  return {
    availableCashSection,
    initialLoanBalanceSection,
    homeInformationSection,
    youngestBorrowerSection,
    loanDetailsSection,
  };
}
