import {Box} from '@material-ui/core';
import {green} from '@material-ui/core/colors';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import clsx from 'clsx';
import Title from 'components/common/typeography/title/Title';
import useStyles from 'components/illustrator/modal-email-to-borrower/components/illustrations-sent/IllustrationsSentStyles';
import {withDialogState} from 'hooks/dialog';
import {useTranslation} from 'lib/i18n';
import {useEffect} from 'react';

/**
 *
 * "Illustration Sent" component
 *
 *    parent component : modal "Email to Borrower"
 *
 */

function IllustrationsSent(props = {}) {
  // props
  const classes = useStyles();
  const {t} = useTranslation();

  const {setState} = props;
  useEffect(() => {
    setState((preState) => ({...preState, closeDialogAndSendAnalyticsEvent: false}));
  }, [setState]);

  return (
    <>
      <Box className={classes.textAlignCenter}>
        <CheckCircleOutlinedIcon style={{marginTop: 10, fontSize: 60, color: green[500]}} />
      </Box>

      <Box>
        <Title variant="h6" className={clsx(classes.textAlignCenter)}>
          {t('illustratorTranslation:modal email to borrower.illustration sent.subtitle')}
        </Title>
      </Box>
    </>
  );
}

export default withDialogState(IllustrationsSent, {
  title: 'illustratorTranslation:modal email to borrower.illustration sent.title',
  formId: '',
  buttonReturn: 'translation:common.return to illustration',
  buttonCancel: '',
  buttonNext: '',
});
