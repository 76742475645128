import {useDateOfBirthValidation} from 'hooks/validator';
import {BorrowerProfileJumboRate, BorrowerValidation} from 'lib/validation/validation';
import {useMemo} from 'react';

export function useIllustrationV2FormContextValues(props = {}) {
  const {Zip, ApiRoute, enabled, fields = {}} = props;

  const DateOfBirthValidation = useDateOfBirthValidation({Zip, ApiRoute, enabled});
  const defaultState = useMemo(
    () => ({
      validationSchema: BorrowerValidation({
        ...fields,
        DateOfBirthValidation,
      }),
    }),
    [fields, DateOfBirthValidation],
  );

  const extraArguments = useMemo(
    () => ({
      validationSchema: BorrowerValidation({
        ...fields,
        DateOfBirthValidation,
        BorrowerProfile: BorrowerProfileJumboRate(),
      }),
    }),
    [fields, DateOfBirthValidation],
  );

  return {defaultState, extraArguments};
}
