export function withLineProps(props) {
  const {hide, showTooltip = false, tooltipType, legendType, display, activeDot = {r: 8}, ...rest} = props;

  return {
    type: 'monotone',
    activeDot: hide ? {r: 0} : activeDot,
    legendType: hide ? 'none' : legendType,
    display: hide ? 'none' : display,
    tooltipType: hide && !showTooltip ? 'none' : tooltipType,
    ...rest,
  };
}
