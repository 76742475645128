import tierImg from 'assets/images/products/tier.svg';
import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import Text from 'components/common/typeography/text/Text';
import TierRatesSelect from 'components/products/tier/TierRatesSelect';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import {formatCurrency} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation, getFundAccessValidation} from 'lib/validation/validation';
import {map} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';

const additionalStepValidation = yup.object({
  HomeSafeMetricId: yup.number(),
  LenderCredit: yup.bool(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.TIER], 'value')}),
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.TIER,
    annualPayment: yup.number().max(0),
    monthlyPayment: yup.number().max(0),
  }),
});

export function useTierHook(props = {}) {
  const {
    product: {
      calculation: {OverrideClosingCosts, OverrideClosingCostsKey, ClosingCost, SimplifiedCashToBorrower} = {},
    } = {},
  } = props;

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const [maxCashAtClose, setMaxCashAtClose] = useState(SimplifiedCashToBorrower);

  useEffect(() => {
    setMaxCashAtClose(SimplifiedCashToBorrower);
  }, [SimplifiedCashToBorrower]);

  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [maxClosingCost, setMaxClosingCost] = useState(ClosingCost);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
    if (!OverrideClosingCosts) {
      setMaxClosingCost(ClosingCost);
    }
  }, [ClosingCost, OverrideClosingCosts, OverrideClosingCostsKey]);

  const toggleDisabled = useCallback((event) => {
    setIsDisabled(!event);
  }, []);

  return {
    description: t('illustratorTranslation:illustration.products.tier.description'),

    img: tierImg,

    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'homeValueYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
      ],
      [],
    ),
    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.tier.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(
      () => [
        <div order="6">
          {!isDisabled && (
            <Text order="6" variant="caption" colorVariant="grey">
              Max closing costs {formatCurrency(maxClosingCost)}
            </Text>
          )}
        </div>,
        <Switch order="7" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
      ],
      [isDisabled, maxClosingCost, toggleDisabled],
    ),
    additionalStep: useMemo(
      () => [
        <TierRatesSelect order="1" name="HomeSafeMetricId" />,
        <Switch order="2" name="LenderCredit" defaultValue={false} />,
        <Input order="3" name="CashAtClose" type="currency" defaultValue={0} disabled={isShortToClose} />,
        <div order="4">
          {!isShortToClose && (
            <Text order="4" variant="caption" colorVariant="grey">
              Cash cannot exceed {formatCurrency(maxCashAtClose)}
            </Text>
          )}
        </div>,
        <Input order="5" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <FundAccessInput
          order="8"
          type="hidden"
          defaultValue={FUND_ACCESS.LINE_OF_CREDIT}
          ApiRoute={API_ROUTES.TIER}
        />,
      ],
      [isDisabled, isShortToClose, maxCashAtClose],
    ),
  };
}
