import Container from 'components/common/layout/container/Container';
import Item from 'components/common/layout/item/Item';
import List from 'components/common/list/List';
import Text from 'components/common/typeography/text/Text';
import useStyles from 'components/illustrator/illustrator-amortization-table-header-note/IllustratorAmortizationTableHeaderNoteStyles';
import {API_ROUTES} from 'constants/illustrator';
import {useProductContext} from 'contexts/product-context';
import {useTranslation} from 'lib/i18n';
import React, {useMemo} from 'react';
import 'react-data-grid/dist/react-data-grid.css';

function getColumns({productDetails, product, t}) {
  let headerNotes = productDetails.tableHeaderNotes;
  if (product.ApiRoute !== API_ROUTES.ANNUAL) {
    return [];
  }
  return headerNotes
    .filter((col) => (typeof col.condition === 'boolean' ? col.condition : true))
    .map((col) => ({
      ...col,
      listDataText: col.listData
        ? t(`illustratorTranslation:illustration.advanced options.${col.listData}`)
        : undefined,
    }));
}

function IllustratorAmortizationTableHeaderNote() {
  const classes = useStyles();
  const {product, productDetails} = useProductContext();
  const {t} = useTranslation();

  const notes = useMemo(() => getColumns({productDetails, product, t}), [product, productDetails, t]);

  return (
    <Container className={classes.notesListContainer}>
      <Item className={classes.notesListItem}>
        <List flexDirection="column" listItemClassName={classes.notesListInfo}>
          {notes.map((d) => (
            <React.Fragment key={d.listDataText}>
              <Text variant="body2" className={classes.noteLabel}>
                {'NOTE: '}
              </Text>
              <Text variant="body2" data-testid={`amortization-table-header-note-${d.listDataText}-value`}>
                {d.listDataText}
              </Text>
            </React.Fragment>
          ))}
        </List>
      </Item>
    </Container>
  );
}

export default React.memo(IllustratorAmortizationTableHeaderNote);
