import existingMortgageImg from 'assets/images/products/existingloan.svg';
import yup from 'lib/validation';
import {useMemo} from 'react';

const additionalStepValidation = yup.object({});

export function useExistingMortgageHook(props = {}) {
  const overallResults = useMemo(() => [], []);
  const overallResultsSecond = useMemo(() => [], []);

  return {
    overallResults,
    overallResultsSecond,

    img: existingMortgageImg,

    additionalAdvancedOptionHeader: [],
    additionalStepTitle: '',
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: [],
    additionalStep: [],
  };
}
