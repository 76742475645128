import {RESET_FORM_EVENT} from 'constants/event';
import {isEqual, omit} from 'lodash';
import {useCallback, useMemo, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTimeoutFn, useUnmount, useUpdateEffect} from 'react-use';

function getEventName(id) {
  return `${RESET_FORM_EVENT}-${id}`;
}

export function useResetForm(props) {
  const {defaultValues: dv, enabled, id, defaultValuesOmit = [], timeOut = 500} = props;

  const prevDefaultValuesRef = useRef();
  const defaultValues = useMemo(() => omit(dv, defaultValuesOmit), [dv, defaultValuesOmit]);

  const timeoutFn = useCallback(() => {
    if (enabled && !isEqual(prevDefaultValuesRef.current, defaultValues)) {
      const name = getEventName(id);
      window.dispatchEvent(new CustomEvent(name, {detail: defaultValues}));
      prevDefaultValuesRef.current = defaultValues;
    }
  }, [defaultValues, enabled, id]);

  // eslint-disable-next-line no-unused-vars
  const [isReady, cancel, reset] = useTimeoutFn(timeoutFn, timeOut);

  useUpdateEffect(() => {
    return () => {
      reset();
    };
  }, [defaultValues]);

  useUnmount(() => cancel());
}

export default function ResetForm(props) {
  const {id} = props;
  const {resetForm} = useFormContext();

  useUpdateEffect(() => {
    const eventName = getEventName(id);

    function listener(event) {
      resetForm(event.detail);
    }

    window.addEventListener(eventName, listener);

    return () => {
      window.removeEventListener(eventName, listener);
    };
  }, [resetForm, id]);

  return null;
}
