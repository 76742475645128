import {useOwnFormContext} from 'contexts/form-context';
import {get, pick} from 'lodash';
import {useCallback} from 'react';
import {cleanUpIllustration} from 'reducers/illustrator.reducer';

export function useIllustratorValidationCleanUp(props = {}) {
  const {validationSchema} = useOwnFormContext();

  const cleanUpData = useCallback(
    (data, options = {}) => {
      const {defaultData} = options;

      return cleanUpIllustration(pick(data, get(validationSchema, '_nodes')), defaultData);
    },
    [validationSchema],
  );

  return {cleanUpData};
}
