import {PRODUCT_FEATURE_KEY} from 'constants/product-feature-key';
import {ZIP_CODE} from 'constants/regex';
import {useProductContext} from 'contexts/product-context';
import {useEnabledProductFeatures} from 'hooks/product-features';
import yup from 'lib/validation';
import {FirstLineValidation} from 'lib/validation/validation';
import YupHoaMonthlyFees from 'pages/illustrator-borrower/property/components/YupHoaMonthlyFees';
import {useMemo} from 'react';

/**
 *
 * Yup validation schema for the "combined property" page within the "borrower flow".
 *
 * If the additional data has all the props set, the yup validation schema might not need enhancements.
 *
 */

function useYupValidationSchema(props) {
  const {product: productSelected} = useProductContext();

  const {isEnabled: isEnabledHOAMonthlyFees} = useEnabledProductFeatures(PRODUCT_FEATURE_KEY.BORROWER_HOA_MONTHLY_FEES);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flagMailAddressRequired = useMemo(
    {
      is: 'false',
      then: (s) => s.required(),
      otherwise: (s) => s.strip(),
    },
    [],
  );

  return useMemo(
    () =>
      yup
        .object({
          //
          // "property info" page attributes
          //
          propertyAddress: yup.string().required(),
          propertyAddress2: yup.string(),
          propertyCity: yup.string().required(),
          propertyState: yup
            .string()
            .required()
            .test(
              'no-selected-product',
              'Make sure that a product was selected.', // dummy text
              function (value) {
                return !!productSelected;
              },
            )
            .test(
              'selected-product-not-available-in-selected-state',
              'The selected product is not available for the selected state.', // dummy text
              function (value) {
                const {path, createError} = this;

                if (productSelected) {
                  // a selected product is available
                  // check if the selected state is available for the selected product
                  let result = productSelected?.ProductAvailableStates?.some((el) => el.State === value);

                  if (result) {
                    // the selected state is available for the selected product
                    return true;
                  } else {
                    // the selected state is not available for the selected product
                    return createError({
                      path,
                      params: {ProductName: productSelected.ProductName},
                    });
                  }
                } else {
                  // we need to validate the selection, because somehow ... there is no selected product
                  return true;
                }
              },
            ),
          propertyZipCode: yup.string().matches(ZIP_CODE).min(0).max(9).required(),
          propertyHomeValue: yup.string().required(),
          propertyMortgagePayoff: yup.string(),
          flagMailAddress: yup.string().required(),
          //
          // "mailing address" page attributes
          //

          mailingAddress: yup.string().when('flagMailAddress', flagMailAddressRequired),
          mailingAddress2: yup.string(),
          mailingCity: yup.string().when('flagMailAddress', flagMailAddressRequired),
          mailingState: yup.string().when('flagMailAddress', flagMailAddressRequired),
          mailingZipCode: yup.string().matches(ZIP_CODE).min(0).max(9).when('flagMailAddress', flagMailAddressRequired),
          //
          // "property taxes" page attributes
          //
          annualCountyTaxAmount: yup.string(),
          annualHazardInsuranceAmount: yup.string(),
          propertyType: yup.string().required(),
          NumberOfUnits: yup.string().required(),
          propertyFirstLien: FirstLineValidation({ApiRoute: productSelected.ApiRoute}),
          ...YupHoaMonthlyFees({enabled: isEnabledHOAMonthlyFees}),
        })
        .shape({
          //
          // "additional properties form list" page attributes
          //
          apFormList: yup.array().of(
            yup.object().shape({
              streetAddress: yup.string().max(200),
              unitNumber: yup.string().max(200),
              propertyCity: yup.string().max(200),
              propertyState: yup.string().max(200),
              propertyZip: yup
                .string()
                .matches(/^$|^[0-9]+$/)
                .min(0)
                .max(9),
            }),
          ),
        }),
    [flagMailAddressRequired, productSelected, isEnabledHOAMonthlyFees],
  );
}

export default useYupValidationSchema;
