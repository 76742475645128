import {WidthContextProvider} from 'contexts/width-context';
import {useCleanupWidth, useWithWidth} from 'hooks/width';
import React, {useMemo} from 'react';

/**
 *
 * @param props {GridProps & {Component:React.Component}}
 * @return {JSX.Element}
 * @constructor
 */
function WithWidth(props) {
  const {xs, sm = xs, md = sm, lg = md, xl = lg, Component, ...rest} = props;
  const widthProps = useMemo(() => ({xs, sm, md, lg, xl}), [lg, md, sm, xl, xs]);

  const {width} = useWithWidth();
  const withPropsResult = useCleanupWidth(widthProps);
  const isFullSize = useMemo(() => widthProps[width] === 12, [widthProps, width]);

  return (
    <WidthContextProvider extraArguments={{isFullSize, width}}>
      {widthProps[width] === 0 ? null : <Component {...rest} {...withPropsResult} />}
    </WidthContextProvider>
  );
}

function withWidth(Component) {
  return function WithWidthRender(props) {
    return <WithWidth Component={Component} {...props} />;
  };
}

export default withWidth;
