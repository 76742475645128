import {lighten} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {isSmSizeAppQuery} from 'lib/app-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      opacity: 1,
    },
    [isSmSizeAppQuery(theme)]: {
      minWidth: 'unset',
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
  wrapper: {
    flexDirection: 'column-reverse',
  },

  roundedButton: {
    padding: theme.spacing(1),
    '&.Mui-selected $wrapper': {
      borderColor: theme.palette.primary.main,
    },
    '& $wrapper': {
      padding: theme.spacing(0.75),
      border: `groove ${theme.spacing(0.25)}px ${lighten(theme.palette.grey.main, 0.85)}`,
      borderRadius: theme.spacing(4),
    },
  },
}));

export default useStyles;
