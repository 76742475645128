import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import FundAccessInput from 'components/common/inputs/FundAccessInput';
import TaxesAndInsuranceReserveInput from 'components/common/inputs/TaxesAndInsuranceReserveInput';
import Text from 'components/common/typeography/text/Text';
import TierRatesSelect from 'components/products/tier/TierRatesSelect';
import {API_ROUTES, FUND_ACCESS} from 'constants/illustrator';
import {formatCurrency} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import yup from 'lib/validation';
import {getAdvancedOptionsFieldDefaultValidation} from 'lib/validation/validation';
import {useCallback, useEffect, useMemo, useState} from 'react';

const additionalStepValidation = yup.object({
  advancedOptions: getAdvancedOptionsFieldDefaultValidation({
    ApiRoute: API_ROUTES.TIER,
    annualPayment: yup.number().max(0),
    monthlyPayment: yup.number().max(0),
  }),
});

export function useFlexHook(props = {}) {
  const {product: {calculation: {ClosingCost, OverrideClosingCosts, OverrideClosingCostsKey} = {}} = {}} = props;
  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [maxClosingCost, setMaxClosingCost] = useState(ClosingCost);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
    if (!OverrideClosingCosts) {
      setMaxClosingCost(ClosingCost);
    }
  }, [ClosingCost, OverrideClosingCosts, OverrideClosingCostsKey]);

  const toggleDisabled = useCallback((event) => {
    setIsDisabled(!event);
  }, []);
  return {
    description: t('illustratorTranslation:illustration.products.flex.description'),

    additionalAdvancedOptionHeader: [],
    additionalStepTitle: t('illustratorTranslation:illustration.products.flex.additional step title'),
    skipAdditionalStep: true,
    additionalStepValidation,
    additionalQuickFill: useMemo(
      () => [
        <div order="7">
          {!isDisabled && (
            <Text order="7" variant="caption" colorVariant="grey">
              Max closing costs {formatCurrency(maxClosingCost)}
            </Text>
          )}
        </div>,
        <Switch order="8" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
      ],
      [isDisabled, maxClosingCost, toggleDisabled],
    ),
    additionalStep: useMemo(
      () => [
        <TierRatesSelect order="1" name="HomeSafeTier" />,
        <Switch order="2" name="LenderCredit" defaultValue={false} />,
        <Input order="3" name="YearsRemaining" type="number" defaultValue={10} />,
        <Input order="4" name="CashAtClose" type="currency" defaultValue={0} />,
        <TaxesAndInsuranceReserveInput order="5" defaultValue={0} />,
        <Input order="6" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <FundAccessInput
          ApiRoute={API_ROUTES.FLEX}
          order="9"
          type="hidden"
          defaultValue={FUND_ACCESS.LINE_OF_CREDIT}
        />,
      ],
      [isDisabled],
    ),
  };
}
