import {Tooltip as MuiTooltip} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, {useMemo} from 'react';

export const TOOLTIP_POSITION = {
  START: 'start',
  END: 'end',
};

export const TOOLTIP_ICON_FONT_SIZE = {
  inherit: 'inherit',
  default: 'default',
  small: 'small',
  large: 'large',
};

/**
 *
 * @param props {TooltipProps}
 * @param props.position {TOOLTIP_POSITION.START | TOOLTIP_POSITION.END}
 * @param props.position {TOOLTIP_ICON_FONT_SIZE.inherit | TOOLTIP_ICON_FONT_SIZE.default | TOOLTIP_ICON_FONT_SIZE.small | TOOLTIP_ICON_FONT_SIZE.large}
 * @return {JSX.Element}
 * @constructor
 */
const Tooltip = React.forwardRef(function TooltipRender(props, ref) {
  const {children, position, fontSize, title, tooltipIconClassName, ...rest} = props;

  return (
    <MuiTooltip {...rest} title={title} ref={ref}>
      <TooltipContent
        children={children}
        position={position}
        fontSize={fontSize}
        showIcon={!!title}
        tooltipIconClassName={tooltipIconClassName}
      />
    </MuiTooltip>
  );
});

const TooltipContent = React.forwardRef(function TooltipContentRender(props, ref) {
  const {
    children,
    position = TOOLTIP_POSITION.START,
    fontSize = TOOLTIP_ICON_FONT_SIZE.small,
    showIcon,
    tooltipIconClassName,
    ...rest
  } = props;

  const icon = useMemo(
    () => showIcon && <InfoOutlinedIcon className={tooltipIconClassName} fontSize={fontSize} />,
    [fontSize, showIcon, tooltipIconClassName],
  );

  return (
    <div {...rest} ref={ref}>
      {position === TOOLTIP_POSITION.START && icon}
      {children}
      {position === TOOLTIP_POSITION.END && icon}
    </div>
  );
});

export default Tooltip;
