import {makeStyles} from '@material-ui/core/styles';
import {isMobileAppQuery} from 'lib/app-theme';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      backgroundColor: theme.palette.white.light,
      display: theme.withPrint('flex', 'block'),
    },
    illustratorBorrowerSpeedDial: {
      [isMobileAppQuery(theme)]: {
        top: '80px !important',
      },
    },
    illustratorBorrowerFab: {
      marginRight: `${theme.spacing(1)}px !important`,
    },
    illustratorBorrowerActions: {
      margin: `${theme.spacing(2, 1, 0, 0)} !important`,
    },
    illustratorBorrowerInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: `${theme.spacing(1, 0, 0, 0)} !important`,
    },

    noPaddingContainer: {
      padding: `${theme.spacing(0)}px !important`,
    },
  }),
  {index: 1},
);

export default useStyles;
