import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '50px',
      width: '100%',
      padding: '0px',
      '& $leftPillActive': {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
      },
      '& $rightPillActive': {
        backgroundColor: theme.palette.grey.lighter + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
      },
    },
    hide: {
      display: 'none',
    },
    fullscreen: {
      width: '100%',
    },
    switchBase: {
      fontSize: '1rem !important',
      fontWeight: 500,
    },
    pill: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.grey.lighter,
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      textTransform: 'none !important',
      width: '50%',
    },
    leftPillActive: {},
    leftPillInactive: {
      backgroundColor: theme.palette.primary.contrastText + ' !important',
      color: theme.palette.grey.lighter + ' !important',
    },
    rightPillActive: {},
    rightPillInactive: {
      backgroundColor: theme.palette.primary.contrastText + ' !important',
      color: theme.palette.grey.lighter + ' !important',
    },
    marginLeftNone: {
      marginLeft: '0 !important',
    },
    marginRightNone: {
      marginRight: '0 !important',
    },
    formControlLabelElement: {
      fontWeight: 600,
      fontSize: '1rem',
      color: theme.palette.primary.dark,
    },
  }),
  {index: 1},
);

export default useStyles;
